import Skeleton from 'react-loading-skeleton'

export const ProductTableRowSkeleton = () => {
  return (
    <tr className="dark:bg-gray-850">
      <td className="pl-5 py-3 rounded-l">
        <div className="flex flex-row items-center">
          <div className="flex flex-row justify-start items-center relative">
            <Skeleton circle width={26} height={26} />
            <Skeleton
              circle
              width={26}
              height={26}
              className="absolute z-10 border-gray-850"
              style={{ left: -6 }}
            />
          </div>
          <Skeleton width={120} height={20} className="ml-0.5 type-sm-semibold" />
        </div>
      </td>
      <td className="py-3">
        <Skeleton width={60} height={20} />
      </td>
      <td className="py-3">
        <Skeleton width={60} height={20} />
      </td>
      <td className="py-3">
        <Skeleton width={60} height={20} />
      </td>
      <td className="pr-5 py-3 rounded-r">
        <Skeleton width={220} height={20} />
      </td>
    </tr>
  )
}

export const RewardTableRowSkeleton = () => {
  return (
    <tr className="bg-gray-850">
      <td className="pl-5 py-3 rounded-l">
        <div className="flex flex-row items-center">
          <div className="flex flex-row justify-start items-center relative">
            <Skeleton circle width={26} height={26} />
            <Skeleton
              circle
              width={26}
              height={26}
              className="absolute z-10 border-gray-850"
              style={{ left: -6 }}
            />
          </div>
          <Skeleton width={105} height={20} className="ml-0.5 type-sm-semibold" />
        </div>
      </td>
      <td className="py-3">
        <Skeleton width={155} height={20} />
      </td>
      <td className="py-3">
        <Skeleton width={155} height={20} />
      </td>
      <td className="py-3">
        <Skeleton width={175} height={20} />
      </td>
    </tr>
  )
}
