import { ClaimParams } from '@apps-orangefi/hooks'
import { useClaimStrykeRewards } from '@apps-orangefi/wagmi/hooks'
import { useCallback, useEffect, useState } from 'react'

export const useClaimStrykeRewardsForm = (
  distributorAddress: AddressType | undefined,
  myClaims: ClaimParams,
  isClaimable: boolean,
  refetch: () => void
) => {
  const [isNeedRefetch, setIsNeedRefetch] = useState(false)

  const claim = useClaimStrykeRewards(
    distributorAddress,
    myClaims.vaultAddressList,
    myClaims.rewardTokenAddressList,
    myClaims.amounts,
    myClaims.merklProofsList,
    isClaimable,
    {
      success: useCallback(() => {
        setTimeout(() => {
          setIsNeedRefetch(true)
        }, 5000)
      }, []),
    }
  )

  useEffect(() => {
    if (isNeedRefetch) {
      refetch()
      setIsNeedRefetch(false)
    }
  }, [isNeedRefetch])

  const onClaim = useCallback(() => {
    claim.onClaim()
  }, [claim.onClaim])

  return {
    onClaim,
    isClaimReady: claim.isClaimReady,
  }
}
