import { VaultInfoList, productKey } from '@apps-orangefi/lib/types'
import { zeroAddress } from 'viem'
import { arbitrum } from 'wagmi/chains'

export const CONTRACT_ADDRESSES: VaultInfoList = {
  [arbitrum.id]: [
    {
      key: productKey.CamelotUsdcDaiVault,
      VAULT_ADDRESS: '0xd6ecEb3978bf2b76958b96E8A207246b98C7d639',
    },
    {
      key: productKey.CamelotUsdcUsdceVault,
      VAULT_ADDRESS: '0x59671Aa8F4E5adCcb26f4c88F0d6047B0ae7620b',
    },
    {
      key: productKey.CamelotUsdceUsdtVault,
      VAULT_ADDRESS: '0x0B48540e214bc196e1D0954834876691fE19068D',
    },
    // NOTE: release after chapter 2
    // {
    //   key: productKey.CamelotWstethWethVault,
    //   VAULT_ADDRESS: '0x738D72b8ed405DE32B9Bc74065D119534018C6d7',
    // },
    // {
    //   key: productKey.CamelotWeethWethVault,
    //   VAULT_ADDRESS: '0xCF3cC7EF67C24cB2B8536533A0376f1f0F62aF5F',
    // },
    // {
    //   key: productKey.CamelotEzethWethVault,
    //   VAULT_ADDRESS: '0x5F5953f974F47f3cbCb60A34AD713eB8786861E2',
    // },
    {
      key: productKey.DeprecatedStrykeWethUsdcVault,
      VAULT_ADDRESS: '0x65Fb7fa8731710b435999cB7d036D689097548e8',
    },
    {
      key: productKey.StrykeWethUsdcVault,
      VAULT_ADDRESS: '0xe1B68841E764Cc31be1Eb1e59d156a4ED1217c2C',
    },
    {
      key: productKey.StrykeWbtcUsdcVault,
      VAULT_ADDRESS: '0x01E371c500C49beA2fa985334f46A8Dc906253Ea',
    },
    {
      key: productKey.StrykeArbUsdcVault,
      VAULT_ADDRESS: '0x708790D732c5886D56b0cBBEd7b60ABF47848FaA',
    },
    {
      key: productKey.StrykeBoopWethVault,
      VAULT_ADDRESS: '0x3D2692Bb38686d0Fb9B1FAa2A3e2e5620EF112A9',
    },
    {
      key: productKey.StrykePancakeWethUsdcVault,
      VAULT_ADDRESS: '0x5f6D5a7e8eccA2A53C6322a96e9a48907A8284e0',
      STRYKE_GAUGE_ID: '0xe88d0e769f1e48dd7558b3eacbaafc2a70a7e64544f9d5f0c828ef940c4644ab',
    },
    {
      key: productKey.StrykePancakeWbtcUsdcVault,
      VAULT_ADDRESS: '0x22dd31a495CafB229131A16C54a8e5b2f43C1162',
      STRYKE_GAUGE_ID: '0x033b588df546c627e60a6724cf872cf97ad9bb2b8b250e6c0e2a5694a71bab9d',
    },
    {
      key: productKey.StrykePancakeArbUsdcVault,
      VAULT_ADDRESS: '0xE32132282D181967960928b77236B3c472d5f396',
      STRYKE_GAUGE_ID: '0x1c421ee9e68e13a94304fa6cea0776ee27a6d9c9aa40b3dd52b4f092436fae84',
    },
    {
      key: productKey.StrykeSushiWethUsdcVault,
      VAULT_ADDRESS: '0x49F60f02B45087ed99EcC4dE63D0337db0d0c6BF',
    },
    {
      key: productKey.StrykeSushiWbtcUsdcVault,
      VAULT_ADDRESS: '0x5bb109E834A4e4c5422526f0f3d42783031BA80d',
    },
  ],
}

export const STRYKE_VAULT_INSPECTOR_ADDRESS: AddressType =
  '0xddB986Dd1d35320eF76D9343B646B3EDCb8C75aF'

export const RESERVE_PROXY_ADDRESS: AddressType = '0xDaDeD9891a65f19E10e67287eE27a7140113E621'

export const SPACESHIP_DISTRIBUTOR_ADDRESS: AddressType =
  '0x889a2802b2b3393b009Ec611529cDd98f0668A2f'

export const ASTRONAUT_NFT_ADDRESS: AddressType = '0xD148f43b4e50D40D961CF6A1d930BCA7C2b3F74e'
export const ASTRONAUT_PROXY_ADDRESS: AddressType = '0x5Cf35C3C5656dDC36b61Db1d90F5941ceE9a6C42'

export const STRYKE_DISTRIBUTOR_ADDRESS: AddressType = '0x37a3c59D17c94126D2aD55EeBf658CAebDe83D90'
export const STRYKE_REWARD_TOKEN_ADDRESS: AddressType = '0xACC51FFDeF63fB0c014c882267C3A17261A5eD50' // SYK
