import { useDebounce, useGetInvalidateQueries } from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { StrykeUniV3HandlerV2ABI } from '@apps-orangefi/wagmi/abis'
import {
  useSimulateContractWithErrorHandling,
  useReadContractWithErrorHandling,
  useWriteContractWithErrorHandling,
  useWaitForTransactionReceiptWithErrorHandling, // Added import
} from '@apps-orangefi/wagmi/hooks/common'
import { useEffect, useMemo, useState } from 'react'
import { BaseError } from 'wagmi'

import '@apps-orangefi/lib/extensions'

export const useIsApporvedStrykeLP = (
  handlerAddress: AddressType | undefined,
  account: AddressType | undefined,
  operatorAddress: AddressType | undefined | null
) => {
  const args = useMemo(() => {
    return [account!, operatorAddress!] as const
  }, [account, operatorAddress])

  const enabled = !!handlerAddress && !!account && !!operatorAddress

  const { data, isFetching, isFetched, queryKey } = useReadContractWithErrorHandling({
    address: handlerAddress!,
    abi: StrykeUniV3HandlerV2ABI,
    functionName: 'isOperator',
    args,
    query: {
      enabled,
    },
  })
  const { refresh } = useGetInvalidateQueries({ queryKey })

  return {
    isApproved: !!data,
    isFetching,
    isFetched,
    refresh,
  }
}

export const useApproveStrykeLP = (
  handlerAddress: AddressType | undefined,
  operatorAddress: AddressType | undefined | null,
  isEnabled: boolean,
  callback?: { success?: () => void; fail?: (cause: BaseError | string) => void }
) => {
  const [isWriteReady, setIsWriteReady] = useState(false)
  const args = useMemo(() => {
    return [operatorAddress!, true] as const
  }, [operatorAddress])
  const enabled = isEnabled && !!handlerAddress && !!operatorAddress

  const { data } = useSimulateContractWithErrorHandling({
    address: handlerAddress!,
    abi: StrykeUniV3HandlerV2ABI,
    functionName: 'setOperator',
    args,
    query: {
      enabled,
    },
  })

  const { data: hash, writeContract } = useWriteContractWithErrorHandling({
    mutation: {
      onError(error) {
        if (callback && callback.fail) {
          callback.fail(error.cause as BaseError)
        }
      },
    },
  })

  const [isTransactionEnd, setIsTransactionEnd] = useState(false)
  const {
    isLoading: waitLoading,
    isSuccess,
    isError,
    error,
  } = useWaitForTransactionReceiptWithErrorHandling({
    hash,
  })

  useEffect(() => {
    if (isTransactionEnd) return
    if (isSuccess) {
      if (callback && callback.success) {
        callback.success()
        setIsTransactionEnd(true)
      }
    }
    if (isError && error) {
      if (callback && callback.fail) {
        callback.fail(error.message)
        setIsTransactionEnd(true)
      }
    }
  }, [isSuccess, isError, error])

  useEffect(() => {
    setIsWriteReady(!!writeContract && !!data?.request)
  }, [writeContract, data?.request])

  return {
    isWriteReady,
    hash,
    isSuccess,
    onApprove: () => {
      if (!isWriteReady) {
        return
      }
      writeContract(data!.request)
    },
    waitLoading,
  }
}
