import SykIcon from '@apps-orangefi/assets/images/tokens/syk.svg'
import {
  useV1VaultList,
  useDopexVaultList,
  useRewardsApr,
  useStrykeRewardAPR,
} from '@apps-orangefi/hooks'
import { BN } from '@apps-orangefi/lib'
import { VaultInfo } from '@apps-orangefi/lib/types'
import { get, sortBy } from 'lodash'
import { useCallback, useMemo } from 'react'

export const useVaultList = (
  account: AddressType | undefined,
  vaultInfoList: VaultInfo[],
  chainId: number,
  distributorAddress?: AddressType | undefined
) => {
  const {
    allTvl: v1AllTvl,
    totalFeeRevenue,
    productCardList: v1ProductCardList,
    myVaults: v1MyVaults,
    almAddressList: v1AlmAddressList,
    fetching: v1Fetching,
    reexecuteQuery: v1Refetch,
  } = useV1VaultList(account, vaultInfoList)

  const {
    allTvl: v2AllTvl,
    productCardList: v2ProductCardList,
    myVaults: v2MyVaults,
    almAddressList: v2AlmAddressList,
    fetching: v2Fetching,
    reexecuteQuery: v2Refetch,
  } = useDopexVaultList(account, vaultInfoList, chainId)

  const allTvl = useMemo(() => {
    return (v1AllTvl ?? new BN(0)).plus(v2AllTvl ?? new BN(0))
  }, [v1AllTvl, v2AllTvl])

  const myVaults = useMemo(() => {
    return v1MyVaults.concat(v2MyVaults)
  }, [v1MyVaults, v2MyVaults])

  const almAddressList = useMemo(() => {
    return v1AlmAddressList.concat(v2AlmAddressList)
  }, [v1AlmAddressList, v2AlmAddressList])

  // const { merklRewardsApr, isLoading } = useRewardsApr(chainId, almAddressList)

  const productCardList = useMemo(() => {
    return sortBy(v1ProductCardList.concat(v2ProductCardList), 'tvl').map(item => {
      const vaultInfo = vaultInfoList.find(vault => vault.VAULT_ADDRESS === item.vaultAddress)
      return { ...item, strykeGaugeId: vaultInfo?.STRYKE_GAUGE_ID }
    })
  }, [v1ProductCardList, v2ProductCardList, vaultInfoList])

  const { strykeRewardsApr, sykePriceUsd, isFetching } = useStrykeRewardAPR(
    distributorAddress,
    productCardList
  )

  const allProductCardList = useMemo(() => {
    return productCardList.map(item => {
      // const rewardsApr =
      //   get(merklRewardsApr, item.vaultAddress.toLowerCase() as AddressType, undefined) ?? new BN(0)

      const strykeReward = strykeRewardsApr.find(
        rewardApr => rewardApr?.vaultAddress === item.vaultAddress
      )
      const totalStrykeRewardApr = strykeReward?.totalRewardApr ?? new BN(0)
      const apr = item.feeApr?.plus(totalStrykeRewardApr) ?? null

      return {
        ...item,
        apr,
        strykeReward: {
          totalApr: totalStrykeRewardApr,
          rewardList: strykeReward?.aprList ?? [],
        },
      }
    })
  }, [productCardList, strykeRewardsApr])

  const fetching = useMemo(() => {
    return v1Fetching || v2Fetching || isFetching
  }, [v1Fetching, v2Fetching, isFetching])

  const refetcth = useCallback(() => {
    v1Refetch()
    v2Refetch()
  }, [JSON.stringify(v1Refetch), JSON.stringify(v2Refetch)])

  return {
    allTvl,
    productCardList: allProductCardList,
    myVaults,
    sykePriceUsd,
    // almAddressList,
    fetching,
    refetcth,
  }
}
