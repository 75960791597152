import { BN } from '@apps-orangefi/lib'
import { fetchClaimableStrykeRewards } from '@apps-orangefi/lib/api'
import { getUserPositionsQuery } from '@apps-orangefi/lib/subgraph/queries'
import { VaultReward, ProductCardProps } from '@apps-orangefi/lib/types'
import { convertVaultPositionList } from '@apps-orangefi/lib/utils'
import { useQuery } from '@tanstack/react-query'
import { chain, cloneDeep } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useQuery as useUrqlQuery } from 'urql'

export type ClaimParams = {
  vaultAddressList: AddressType[]
  rewardTokenAddressList: AddressType[]
  amounts: BN[]
  merklProofsList: AddressType[][]
}

type ResultClaimableStrykeRewards = {
  vaultRewards: VaultReward[]
  totalClaimableAmountUsd: BN
  claimParams: ClaimParams
  isFetching: boolean
  refetch: () => void
}

const defaultClaimParams: ClaimParams = {
  vaultAddressList: [],
  rewardTokenAddressList: [],
  amounts: [],
  merklProofsList: [],
}

export const useClaimableStrykeRewards = (
  account: AddressType | undefined,
  strykeRewardTokenAddress: AddressType,
  chainId: number,
  productList: ProductCardProps[],
  sykPriceUsd: BN | undefined
): ResultClaimableStrykeRewards => {
  const [vaultRewards, setVaultRewards] = useState<VaultReward[]>([])
  const [claimParams, setClaimParams] = useState<ClaimParams>(cloneDeep(defaultClaimParams))
  const [totalClaimableAmountUsd, setTotalClaimableAmountUsd] = useState<BN>(new BN(0))

  const [resultPoints, executeQuery] = useUrqlQuery({
    query: getUserPositionsQuery,
    variables: {
      account: account?.toLowerCase() ?? '',
    },
    pause: !account,
    requestPolicy: 'network-only',
    context: useMemo(
      () => ({
        url: process.env.NEXT_PUBLIC_POINTS_SUBGRAPH_URL,
      }),
      []
    ),
  })
  const {
    data: dataPoints,
    fetching: isFetchingLockPositions,
    error,
  } = useMemo(() => resultPoints, [resultPoints])

  const {
    data: dataStrykeRewards,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['strykeRewards', { user: account, token: strykeRewardTokenAddress, chainId }],
    queryFn: fetchClaimableStrykeRewards,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    let _totalClaimableAmountUsd = new BN(0)

    const userPostions = convertVaultPositionList(productList, dataPoints)
    const _vaultRewards = chain(dataStrykeRewards)
      .map(strykeReward => {
        const vault = productList.find(
          item => item.vaultAddress.toLowerCase() === strykeReward.vault.toLowerCase()
        )
        const userPosition = userPostions.find(
          item => item.vaultAddress.toLowerCase() === strykeReward.vault.toLowerCase()
        )

        if (!vault) {
          return
        }
        if (strykeReward.claimable.eq(0) && !userPosition) {
          return
        }

        const amount = strykeReward.claimable.div(2)
        const subTotalAmountUsd = sykPriceUsd
          ? strykeReward.claimable.multipliedBy(sykPriceUsd)
          : null
        const amountUsd = subTotalAmountUsd ? subTotalAmountUsd.div(2) : null
        const rewards = [
          {
            amount,
            amountUsd,
            symbol: 'SYK',
          },
          {
            amount,
            amountUsd,
            symbol: 'xSYK',
          },
        ]

        _totalClaimableAmountUsd = _totalClaimableAmountUsd.plus(subTotalAmountUsd ?? 0)

        return {
          vaultAddress: vault.vaultAddress as AddressType,
          pairName: vault.title,
          baseTokenSymbol: vault.baseToken.symbol,
          quoteTokenSymbol: vault.quoteToken.symbol,
          amm: vault.platform?.amm,
          derivative: vault.platform?.derivative,
          rewards,
          claim: {
            rewardTokenAddress: strykeReward.token as AddressType,
            amount: strykeReward.claimable,
            proofs: strykeReward.proofs as AddressType[],
          },
        }
      })
      .compact()
      .value()

    const _claimParams = _vaultRewards.reduce((acc, reward) => {
      acc.vaultAddressList.push(reward.vaultAddress)
      acc.rewardTokenAddressList.push(reward.claim.rewardTokenAddress)
      acc.amounts.push(reward.claim.amount)
      acc.merklProofsList.push(reward.claim.proofs)
      return acc
    }, cloneDeep(defaultClaimParams))

    setVaultRewards(_vaultRewards)
    setTotalClaimableAmountUsd(_totalClaimableAmountUsd)
    setClaimParams(_claimParams)
  }, [
    JSON.stringify(productList),
    sykPriceUsd?.toFixed(),
    JSON.stringify(dataStrykeRewards),
    dataPoints,
  ])

  return {
    vaultRewards,
    totalClaimableAmountUsd,
    claimParams,
    isFetching,
    refetch,
  }
}
