import { BN } from '@apps-orangefi/lib'
import axios from 'axios'

type FetchStrykeRewardsQueryKey = {
  queryKey: [string, { user: AddressType | undefined; token: AddressType; chainId: number }]
}

type UserVaultData = {
  vault: AddressType
  token: AddressType
  user: AddressType
  proofs: string[]
  claimedAmount: string
  claimable: string
  cumulativeRewardAmount: string
  balance: string
}

export const fetchClaimableStrykeRewards = async ({ queryKey }: FetchStrykeRewardsQueryKey) => {
  const [_, { user, token, chainId }] = queryKey
  if (!user || !chainId) {
    return undefined
  }
  const url = `https://x-syk-shares-calculator.vercel.app/api/userVaultsData`
  const params = { user, token, chainId }

  try {
    const res = await axios.get<UserVaultData[]>(url, { params })

    return res.data.map(item => {
      return {
        ...item,
        claimedAmount: new BN(item.claimedAmount).pow10ofMinus(18),
        claimable: new BN(item.claimable).pow10ofMinus(18),
        cumulativeRewardAmount: new BN(item.cumulativeRewardAmount).pow10ofMinus(18),
        balance: new BN(item.balance).pow10ofMinus(18),
      }
    })
  } catch {
    return []
  }
}
