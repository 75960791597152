import { BN } from '@apps-orangefi/lib'
import { GetUserPositionsQuery } from '@apps-orangefi/lib/subgraph/types/points/graphql'
import { ProductCardProps, category } from '@apps-orangefi/lib/types'
import dayjs from 'dayjs'
import { chain } from 'lodash'

import { getTimeBoost } from './point'

export const convertVaultPositionList = (
  prodcuctList: ProductCardProps[],
  dataPoints: GetUserPositionsQuery | undefined
) => {
  const vaults = dataPoints?.vaults

  return chain(prodcuctList)
    .map(item => {
      const vaultAddress = item.vaultAddress.toLowerCase() as AddressType
      const lockPositions =
        dataPoints?.user?.stakingPositions
          .filter(sp => sp.vault.id === vaultAddress)
          .map(sp => {
            const lockDuration = dayjs.duration(Number(sp.lockDuration), 'seconds').asDays()
            return {
              id: sp.id,
              lockAmountUSD: new BN(sp.amountUSD),
              unlockAt: dayjs.unix(Number(sp.unlockTime)),
              lockDuration,
              timeBoost: getTimeBoost(lockDuration),
            }
          }) ?? []

      const vaultLastSharePrice = vaults?.find(v => v.id === vaultAddress)?.lastSharePrice
      return {
        vaultAddress,
        tokenPair: {
          baseToken: item.baseToken,
          quoteToken: item.quoteToken,
        },
        platform: item.platform,
        apr: item.apr ?? new BN(0),
        positionUSD: item.myPositionUSD,
        totalLockedUSD: lockPositions.reduce((acc, lp) => acc.plus(lp.lockAmountUSD), new BN(0)),
        lockPositions,
        lastSharePrice: vaultLastSharePrice ? new BN(vaultLastSharePrice) : new BN(0),
        isClosed: item.category === category.Closed,
      }
    })
    .filter(p => {
      return p.totalLockedUSD.gt(0) || (p.positionUSD?.gt(0) ?? false)
    })
    .value()
}
